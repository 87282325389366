<template>
    <div>
      <article>
        <section>
          <div class="country_dialogs">
            <el-dialog
              :title="`Update Hotel`"
              :visible.sync="isEditHotelModalVisible"
              :destroy-on-close="true"
              :before-close="handleClose"
              style="text-align: left"
            >
              <div v-loading="submitting">
                <el-form
                  :model="hotelForm"
                  :rules="rules"
                  label-position="top"
                  ref="hotelForm"
                  class="demo-hotelForm2"
                >
                  
                <div class="row">
                    <div class="col-md-12">
                      <el-form-item label="hotel Title" prop="hotelTitle">
                        <el-input v-model="hotelForm.hotelTitle"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item label="hotelDescription" prop="hotelDescription">
                        <el-input textarea v-model="hotelForm.hotelDescription"></el-input>
                      </el-form-item>
                    </div>
                  </div>
  
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item label="Hotel Photo">
                        <el-upload class="upload-demo" drag action="''" :on-change="handleTourIdeaPhotoPreview"
                          :on-remove="handleTourIdeaPhotoRemove" :file-list="hotelPhotoFileLists" :auto-upload="false"
                          :multiple="false">
                          <div v-if="!isUploadingFile">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">
                              Drop file here or <em>click to upload</em>
                            </div>
                          </div>
                          <div v-else>
                            <el-progress v-if="imageUploadingProgress != 100" type="circle" :color="progressColors"
                              :percentage="imageUploadingProgress" :width="80" :stroke-width="4"></el-progress>
                            <el-progress v-else type="circle" :percentage="100" status="success" :width="80"
                              :stroke-width="4">
                            </el-progress>
                            <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                              {{
                                imageUploadingProgress == 100
                                ? "Uploaded"
                                : "Uploading..."
                              }}
                            </div>
                          </div>
                          <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                        </el-upload>
                      </el-form-item>
                    </div>
                  </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button
                      style="background-color: #083628; color: white;"
                      @click="editHotel('hotelForm')"
                    > Save Hotel Changes</el-button>
                  </el-form-item>
                </el-form>
  
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2024 Bakyala Safaris, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!--v-model="hotelForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: false,
        isLoading: false,
  
        isEditHotelModalVisible: false,
        
        selectedHotelPhotoFile: null,
        isUploadingFile: false,
        imageUploadingProgress: 0,
        progressColors: [
          { color: "#f56c6c", percentage: 25 },
          { color: "#e6a23c", percentage: 50 },
          { color: "#1989fa", percentage: 75 },
          { color: "#6f7ad3", percentage: 100 },
        ],
        hotelPhotoFileLists: [],
        addHotelModal: false,
        submitting: false,
        hotelForm: {
          hotelTitle: "",
          hotelDescription: "",
        },
        rules: {
          hotelTitle: [
            {
              required: true,
              message: "Title is required",
              trigger: "blur",
            },
          ],
          hotelDescription: [
            {
              required: true,
              message: "Description is required",
              trigger: "blur",
            },
          ],
          
        },
      };
    },
  
    props: {
      showHotelEditDialog: {
        required: true,
        type: Boolean,
      },
      hotelData: {
        required: true,
        type: Object,
      },
    },
  
    watch: {
      showHotelEditDialog() {
        if (this.showHotelEditDialog === true) {
          this.isEditHotelModalVisible = true;
          this.hotelForm.hotelTitle = this.hotelData.hotelTitle;
          this.hotelForm.hotelDescription = this.hotelData.hotelDescription;
          this.selectedHotelPhotoFile = this.hotelData.hotelImage;
          this.hotelPhotoFileLists = [
            {
              name: "hotelImage",
              url: "this.hotelData.hotelImage",
            },
          ];
        } else {
          this.isEditHotelModalVisible = false;
        }
      },
    },
  
    methods: {
      handleClose(done) {
        done();
        this.$emit("closeEditHotelDialog");
      },
  
      async editHotel(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              this.isUploadingFile = true;
              const formData = new FormData();
              formData.append("hotelTitle", this.hotelForm.hotelTitle);
              formData.append("hotelDescription", this.hotelForm.hotelDescription);
              formData.append("hotelImage", this.selectedHotelPhotoFile);
              let response = await this.$http.patch(
                `hotels/${this.hotelData.hotelID}`, formData, {
                onUploadProgress: (progressEvent) => {
                  this.imageUploadingProgress = +(
                    (progressEvent.loaded / progressEvent.total) *
                    100
                  ).toFixed(0);
                },
              }
              );
              if (
                response.data.success &&
                response.data.message == "HOTEL_UPDATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "Hotel updated successfully",
                  type: "success",
                });
                this.$emit("closeEditHotelDialog");
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to update Hotel",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
  
  <style>
  </style>